<script setup lang="ts">
import type { PropType } from 'vue';

const props = defineProps({
  tabs: Array as PropType<{ id: string; title: string; count?: number }[]>,
  modelValue: String as PropType<string>,
});
const { tabs, modelValue } = toRefs(props);
const emit = defineEmits(['update:modelValue']);
</script>
<template>
  <div class="flex justify-between space-x-4 border-b border-neutral-200 dark:border-neutral-700">
    <div class="flex">
      <div
        v-for="tab in tabs"
        :key="tab.id"
        :class="[
          modelValue === tab.id
            ? 'border-black text-black dark:border-white dark:text-white'
            : 'border-transparent text-neutral-500 hover:border-neutral-300 hover:text-neutral-700 dark:border-neutral-600 dark:text-neutral-200',
          'flex cursor-pointer whitespace-nowrap border-b-2 px-3 py-4 text-lg font-bold',
        ]"
        @click="emit('update:modelValue', tab.id)"
      >
        {{ $t(tab.title) }}
        <span v-if="tab.count !== undefined" class="inline-block pl-2.5 font-medium">{{ tab.count }}</span>
      </div>
    </div>
    <div>
      <slot></slot>
    </div>
  </div>
</template>
